angular
	.module("ezd.backend")
	.service("$$userSelectProfile", $$userSelectProfile);

$$userSelectProfile.$inject = ["Restangular", "$cookies"];
function $$userSelectProfile(Restangular, $cookies) {
	const vm = this;

	vm.send = send;

	/**
	 * Отправка данных об изменении профиля - родитель выбрал ученика или сотрудник выбрал организацию
	 * Вызов должен происходить до обновления куков: $cookies.put("profile_id", profile.id); / $cookies.put("student_id", profile.id);,
	 * чтоб была возможность сравнить со старым значением и отправлять только при изменении
	 * Альтернативно отправка произойдет, если в куках выставлен флаг init_session = true
	 * @param profile
	 * @param authenticationToken
	 * @returns {Promise<void>}
	 */
	async function send(profile, authenticationToken) {
		if (!profile) {
			return;
		}

		try {
			const initSession = $cookies.get("init_session");
			const authToken = authenticationToken || $cookies.get("auth_token");
			const profileId = _.int($cookies.get("profile_id"));
			const studentId = _.int($cookies.get("student_id"));

			const isStudent = (profile.roles && profile.roles.indexOf("student") > -1) || (profile.type === "student") || (profile.route && profile.route.indexOf("student_profiles") > -1);
			const isParent = (profile.roles && profile.roles.indexOf("parent") > -1) || (profile.type === "parent");
			const isStaff = profile.school_id && !isStudent && !isParent;

			if (authToken) {
				if (isStudent && (initSession || studentId !== profile.id)) {
					if (initSession) {
						$cookies.remove("init_session");
					}
					// зашел сам учащийся
					if (profileId === profile.id) {
						await Restangular.all("authenticate/student_profile").post({
							auth_token: authToken,
							profile_id: profile.id
						});
					}
					// родитель выбрал ученика
					if (profileId !== profile.id) {
						await Restangular.all("authenticate/parent_profile").post({
							auth_token: authToken,
							profile_id: profileId,
							student_profile_id: profile.id
						});
					}
				}

				// родитель зашел и был выбран дефолтный ученик
				if (isParent && studentId && (initSession || profileId !== profile.id)) {
					if (initSession) {
						$cookies.remove("init_session");
					}

					await Restangular.all("authenticate/parent_profile").post({
						auth_token: authToken,
						profile_id: profile.id,
						student_profile_id: studentId
					});
				}

				// сотрудник выбрал организацию
				if (isStaff && (initSession || profileId !== profile.id)) {
					if (initSession) {
						$cookies.remove("init_session");
					}

					await Restangular.all("authenticate/staff_profile").post({
						auth_token: authToken,
						profile_id: profile.id
					});
				}
			}
		} catch (err) {
			console.error(err);
		}
	}
}
